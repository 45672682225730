import $ from 'jquery'
import 'cookieconsent/build/cookieconsent.min'
import 'cookieconsent/build/cookieconsent.min.css'

const url = new URL(location.href)

/* There's a test for this brittle magic string behavior
   It's the name of the cookie consent settting on the theme.
   The theme customizer sets the name of the current selected section on its window object which we can access from the child frame to check if the the admin is customizing the cookie manager. The customizer also dispatches an event on the child frame's window object when the selected section changes.
    If for whatever reason you want to change the name of the setting (in settings_schema.json) - make sure to update it over here as well
  */
const COOKIE_CONSENT_MANAGER_THEME_SETTING_NAME = 'Cookie consent'

/*
 Returns a hash for the cookie content settings. It will read <body data-cookie-consent-manager> for:

 - consentType - matches the enum on Account
 - popupBg
 - popupText
 - buttonBg
 - buttonText
 - position
 - layout
 - countryCode
 - createConsentRecordURL - we send a post notification here to log consent actions like allow, dismiss, deny

 It will additionally set the following settings

 - alwaysAsk => Asks for consent no matter what (even if cookie conset is not enabled or they have already consented)
    - set to true when cookie_consent_always_ask=1 in the URL params
    - or when the admin is customizing the cookie consent from the theme customizer
 - countryCode - override the countryCode above with the `cookie_consent_country` URL param

 It will calculate:

 - cookie
 - type
 */

let _settings = null

function getSettings() {
  if (_settings != null) {
    return _settings
  }

  let settings = JSON.parse(
    document.body.getAttribute('data-cookie-consent-manager')
  )

  if (settings == null) {
    return null
  }

  if (settings.skip) {
    return settings
  }

  if (settings.consentType == 'opt_in') {
    settings.type = 'opt-in'
  } else {
    settings.type = settings.consentType
  }

  settings.alwaysAsk =
    url.searchParams.get('cookie_consent_always_ask') == '1' ||
    isCustomizingTheme()

  settings.cookie = {}
  if (settings.alwaysAsk) {
    // Use a temporary cookie with a unique name, so even if they accept, we'll ask again on the next page load.
    settings.cookie.name = 'cookie_always_ask_' + new Date().getTime()
    settings.cookie.expiryDays = 0.0001 // 0 is taken as "use default", or 365.
  }
  if (location.protocol == 'https:') {
    settings.cookie.secure = true
  }

  let countryOverride =
    url.searchParams.get('cookie_consent_country') ||
    (isCustomizingTheme() ? 'UK' : null)
  if (countryOverride) {
    settings.countryCode = countryOverride
  }

  _settings = settings
  return settings
}

function isCustomizingTheme() {
  return url.searchParams.get('theme_customization')
}

function isCustomizingCookieConsentManager() {
  return (
    window.top &&
    window.top.themeCustomizationSelectedSectionName ==
      COOKIE_CONSENT_MANAGER_THEME_SETTING_NAME
  )
}

function dontAskForCookieConsent() {
  // We *only* don't want to show the banner at all if we are customizing the theme and not on the cookie consent setting section
  return isCustomizingTheme() && !isCustomizingCookieConsentManager()
}

function inIframe() {
  try {
    return window.self !== window.top
  } catch (e) {
    return false
  }
}

function shouldAskForCookieConsent() {
  const settings = getSettings()

  if (inIframe() && !isCustomizingCookieConsentManager()) {
    return false
  }

  return (
    !dontAskForCookieConsent() &&
    (settings.consentType != 'none' ||
      settings.alwaysAsk ||
      isCustomizingCookieConsentManager())
  )
}

function createConsentRecord(action) {
  const settings = getSettings()

  $.ajax({
    type: 'POST',
    url: settings.createConsentRecordURL,
    data: { url: window.location.href, consent_action: action },
    xhrFields: { withCredentials: true },
  })
}

function initializeCookieConsentManager() {
  const settings = getSettings()

  if (settings == null) {
    console.log('No settings found, going to allow cookies.')
    simpleroCookieConsent.fireCookiesGrantedCallbacks()
    return
  }

  if (settings.skip) {
    // Intentionally skipped
    simpleroCookieConsent.fireCookiesGrantedCallbacks()
    return
  }

  if (!shouldAskForCookieConsent()) {
    // We don't need consent, let callbacks run.
    simpleroCookieConsent.fireCookiesGrantedCallbacks()
    // We don't need to inform either. We're done.
    return
  }

  // If opt-in, we wait for them to opt-in. Anything else, let callbacks run immediately.
  if (settings.consentType != 'opt_in') {
    simpleroCookieConsent.fireCookiesGrantedCallbacks()
  }

  window.cookieconsent.initialise({
    type: settings.type,
    palette: {
      popup: {
        background: settings.popupBg || '#000',
        text: settings.popupText || '#FFF',
      },
      button: {
        background: settings.buttonBg || '#f1d600',
        text: settings.buttonText || '#000',
      },
    },
    position: settings.position || 'bottom-left',
    theme: settings.layout || 'block',
    law: {
      countryCode: settings.countryCode,
    },
    cookie: settings.cookie,
    content: settings.content,
    onInitialise: function (_status) {
      if (settings.consentType == 'opt_in') {
        if (this.hasConsented()) {
          simpleroCookieConsent.fireCookiesGrantedCallbacks()
        } else {
          simpleroCookieConsent.fireCookiesRevokedCallbacks()
        }
      }
    },
    onStatusChange: function (status, _chosenBefore) {
      if (settings.consentType == 'opt_in') {
        if (this.hasConsented()) {
          simpleroCookieConsent.fireCookiesGrantedCallbacks()
        } else {
          simpleroCookieConsent.fireCookiesRevokedCallbacks()
        }
      }

      createConsentRecord(status)
    },
    onRevokeChoice: function () {
      // revoke on allowed = deny
      if (
        settings.consentType == 'opt_in' &&
        simpleroCookieConsent.currentStatus == 'allowed'
      ) {
        simpleroCookieConsent.fireCookiesRevokedCallbacks()
        createConsentRecord('revoke_allowed')
      }
    },
    onNoCookieLaw: function (_countryCode, _country) {
      if (settings.consentType == 'opt_in') {
        simpleroCookieConsent.fireCookiesGrantedCallbacks()
      }
    },
  })
}

window.addEventListener('DOMContentLoaded', initializeCookieConsentManager)

window.addEventListener(
  'simplero:themeSettingSection:selectionChange',
  function () {
    if ($('.cc-window').length == 0) {
      initializeCookieConsentManager()
    } else if (shouldAskForCookieConsent()) {
      $('.cc-window').show()
    } else {
      $('.cc-window').hide()
    }
  }
)

/*
 Support for callbacks from user content. If something should only run when cookie consent has been given, it can be
 run like:

 simpleroCookieConsent.whenCookiesAllowed(function() {
   // use cookies
 })

 The callback will be called onload if consent is already given or not required. If not, it will be called if/when the
 user gives consent.
 */
let simpleroCookieConsent = {
  currentStatus: 'unknown',
  callbacks: {
    allowed: [],
    denied: [],
  },

  // Add a function to be called when cookies are allowed.
  whenCookiesAllowed: function (callback) {
    this.handleCallback('allowed', callback)
  },

  whenCookiesDenied: function (callback) {
    this.handleCallback('denied', callback)
  },

  handleCallback: function (status, callback) {
    if (this.currentStatus == status) {
      callback()
    }
    this.callbacks[status].push(callback)
  },

  // Internal functions - call this when cookies become allowed/denied.
  fireCookiesGrantedCallbacks: function () {
    this.fireCookiesCallbacks('allowed')
  },

  fireCookiesRevokedCallbacks: function () {
    this.fireCookiesCallbacks('denied')
  },

  fireCookiesCallbacks: function (status) {
    if (this.currentStatus != status) {
      this.currentStatus = status
      this.callbacks[status].forEach(function (callback) {
        try {
          callback()
        } catch (ex) {
          console.log(
            'Failure running callbacks for when cookies are ',
            status,
            ': ',
            ex
          )
        }
      })
    }
  },
}

window.simpleroCookieConsent = simpleroCookieConsent
